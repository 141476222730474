.saveInfoBanner {
    box-shadow: 0px 0px 10px 0px rgba(39, 39, 42, 0.15);
    animation: slideDown 300ms ease-in forwards;
}
@keyframes slideDown {
    from {
        transform: translate(0, -150px);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}
