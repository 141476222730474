.breadcrumbs {
    padding: 8px 0;
    height: 92px;
    display: flex;
    align-items: flex-end;
    font-size: 12px;

    a {
        text-transform: capitalize;
        span {
            margin: 0 4px;
        }
    }
}
