.select {
    :global(.react-select__control) {
        border-radius: 0px;
        border-color: #d4d4d8;
        font-size: 16px;
        min-height: 40px;
        box-shadow: none;
        outline: 2px solid transparent !important;
        outline-offset: 2px;

        &:hover {
            border-color: inherit;
        }
        // outline: none;
    }
    :global(:has(.react-select__value-container--has-value)) {
        // border-color: $colorGrey;
    }
    :global(.react-select__indicator-separator) {
        // display: none;
    }
    :global(.react-select__indicator) {
        color: #71717a;
    }
    :global(.react-select__option) {
        &:hover {
            background-color: #fff1f2;
        }
    }
    :global(.react-select__option--is-focused:not(.react-select__option--is-selected)) {
        background-color: #fff1f2;
    }
    :global(.react-select__control--is-focused) {
        outline-color: rgb(253 164 175) !important;
    }

    &.error {
        :global(.react-select__control) {
            background-color: #fff7ed;
        }
    }
}
