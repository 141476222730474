.orderFilter {
    position: relative;

    .dashed {
        border: 1px dashed #d4d4d8;
        height: 36px;

        i {
            font-size: 16px;
            height: 16px;
        }
        &:hover {
            border-color: #be123c;
        }
    }

    .divider {
        width: 1px;
        height: 16px;
        background-color: #e5e7eb;
    }
    .selectedFilter {
        padding: 4px 8px;
        color: #71717a;
        background-color: #fff1f2;
        font-size: 14px;
        line-height: 20px;
        height: 28px;
        display: flex;
        align-items: center;
        gap: 6px;

        i {
            color: #71717a;
            height: 16px;
        }
    }
    .dropdown {
        position: absolute;
        display: none;

        width: 100%;
        width: unset;
        min-width: 260px;
        left: 0;
        padding-top: 14px;
        opacity: 0;
        animation: fadeIn 150ms ease-in forwards;
        z-index: 3;
    }
    .dropdownContent {
        display: flex;
        flex-direction: column;
        padding: 5px;
        width: 100%;
        background-color: #fff;
        // border-radius: 2px;
        color: black;
        white-space: nowrap;
        box-shadow: 0px 4px 6px 0px rgba(24, 24, 27, 0.09);
    }
    .dropdownItem {
        cursor: pointer;
        font-size: 14px;
        padding: 10px 20px;
        transition: all 150ms ease-in-out;
        span {
            color: #a1a1aa;
        }
        &:hover {
            background: #fff1f2;
        }
    }
    &.active {
        .dropdown {
            display: block;
        }
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
