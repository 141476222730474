.content {
    max-width: 460px;
    width: 100%;

    a {
        font-size: 14px;
        text-decoration: underline;
        margin-top: 6px;
        font-weight: 500;
    }
}
