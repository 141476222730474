.login {
    background-image: url('/assets/images/login-bg.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    .logo {
        font-size: 28px;
        color: white;
        padding: 104px 64px;
        font-weight: 900;
    }
}
