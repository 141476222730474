.navigation {
    padding: 56px 0 0 56px;
    width: 256px;

    .logo {
        width: 40px;
        height: 40px;
        margin-bottom: 64px;
    }
    .navLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .navLink {
        padding: 16px 24px;
        height: 56px;
        display: block;
        transition: all 300ms ease-in-out;
        &.active {
            background-color: #be123c;
            color: white;
        }
        &:hover {
            background-color: darken(#be123c, 5%);
            color: white;
        }
    }
    .address {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 32px 24px 56px 24px;
        font-size: 12px;
        line-height: 20px;
        color: #a1a1aa;
    }
}
.trigger {
    position: absolute;
    top: 16px;
    left: 4px;
    font-size: 24px;
    display: none;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    z-index: 1;
    cursor: pointer;
    i {
        height: 28px;
    }
}

@media screen and (max-width: 1200px) {
    .trigger {
        display: flex;
    }
    .navigation {
        position: absolute;
        background: white;
        height: 100%;
        top: 0;
        z-index: 10;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        left: -100%;
        transition: all 300ms ease-in-out;

        &.open {
            left: 0;
        }
    }
}
