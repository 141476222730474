.checkbox {
    display: flex;
    align-items: center;
    gap: 8px;

    .wrapper {
        position: relative;
        height: 14px;
    }

    i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 12px;
        color: white;
        pointer-events: none;
        height: 14px;
        width: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        width: 14px;
        height: 14px;
        border: 2px solid #fb7185;
        display: block;
        outline: none;
        flex-shrink: 0;
        cursor: pointer;
        transition: all 150ms ease-in-out;

        &:hover {
            border-color: #e11d48;
        }

        &.checked {
            background-color: #e11d48;
            border: 2px solid #e11d48;
            position: relative;
        }
        &:disabled {
            border-color: #c0c0c0;
            background-color: #c0c0c0;
            cursor: not-allowed;
            + {
                span {
                    color: #c0c0c0;
                }
            }
        }
    }
}
