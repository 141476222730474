.pageTitle {
    display: flex;
    align-items: center;

    i {
        height: 24px;
        width: 24px;
        margin-right: 8px;
        font-size: 24px;
        &:hover {
            cursor: pointer;
        }
    }
}
.status {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    background: #3f3f46;
    color: #fafafa;
    padding: 0 4px;
    letter-spacing: 0.24px;
}
