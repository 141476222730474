.imageArea1 {
    grid-template-areas: 'i0';
}
.imageArea2 {
    grid-template-areas: 'i0 i1';
}
.imageArea3 {
    grid-template-areas: 'i0 i1 i2';
}
.imageArea4 {
    grid-template-areas:
        'i0 i0 i1 i1'
        'i2 i2 i3 i3';
}
.imageArea5 {
    grid-template-areas:
        'i0 i0 i1 i2'
        'i0 i0 i3 i4';
}

.eventSettings {
    font-size: 14px;
    color: #3f3f46;
    position: relative;

    // .trigger {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     padding: 8px 0;

    //     &:hover {
    //         cursor: pointer;
    //     }
    // }
    .dropdown {
        position: absolute;
        display: none;

        width: 230px;
        right: 0;
        padding-top: 14px;
        opacity: 0;
        animation: fadeIn 150ms ease-in forwards;
    }
    .dropdownContent {
        display: flex;
        flex-direction: column;
        padding: 5px;
        width: 100%;
        background-color: #fafafa;
        border: 1px solid #e4e4e7;
        border-radius: 2px;
        box-shadow: 0px 4px 6px 0px rgba(24, 24, 27, 0.09);
    }
    .dropdownItem {
        padding: 6px 8px 6px 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 300ms ease-in-out;
        line-height: 20px;
        &:hover {
            background-color: #fff1f2;
            cursor: pointer;
        }

        i {
            font-size: 16px;
            height: 16px;
        }
    }
    &.active {
        background-color: #fff1f2;
        .dropdown {
            display: block;
        }
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
