$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("../fonts/icon/icons.eot?0a4c434b9ae7b207e78a42a98b93f801#iefix") format("embedded-opentype"),
url("../fonts/icon/icons.woff2?0a4c434b9ae7b207e78a42a98b93f801") format("woff2"),
url("../fonts/icon/icons.woff?0a4c434b9ae7b207e78a42a98b93f801") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "wheat": "\f101",
    "user": "\f102",
    "user-negative": "\f103",
    "user-draft": "\f104",
    "upload": "\f105",
    "up-down": "\f106",
    "trash": "\f107",
    "three-dots": "\f108",
    "terminate": "\f109",
    "suspend": "\f10a",
    "shareholder": "\f10b",
    "salad": "\f10c",
    "right-arrow": "\f10d",
    "phone": "\f10e",
    "pet-other": "\f10f",
    "person": "\f110",
    "message": "\f111",
    "male": "\f112",
    "mail": "\f113",
    "logo": "\f114",
    "lock": "\f115",
    "list": "\f116",
    "left-arrow": "\f117",
    "invitation": "\f118",
    "heart": "\f119",
    "hamburger": "\f11a",
    "grid": "\f11b",
    "file": "\f11c",
    "female": "\f11d",
    "eye": "\f11e",
    "export": "\f11f",
    "error": "\f120",
    "edit": "\f121",
    "download": "\f122",
    "double-arrow-right": "\f123",
    "double-arrow-left": "\f124",
    "dog": "\f125",
    "doc-chart": "\f126",
    "dislike": "\f127",
    "company": "\f128",
    "company-new": "\f129",
    "close": "\f12a",
    "check": "\f12b",
    "change": "\f12c",
    "cat": "\f12d",
    "carret-down": "\f12e",
    "cake": "\f12f",
    "building": "\f130",
    "block": "\f131",
    "back": "\f132",
    "archive": "\f133",
    "alert": "\f134",
);

.icon-wheat:before {
    content: map-get($icons-map, "wheat");
}
.icon-user:before {
    content: map-get($icons-map, "user");
}
.icon-user-negative:before {
    content: map-get($icons-map, "user-negative");
}
.icon-user-draft:before {
    content: map-get($icons-map, "user-draft");
}
.icon-upload:before {
    content: map-get($icons-map, "upload");
}
.icon-up-down:before {
    content: map-get($icons-map, "up-down");
}
.icon-trash:before {
    content: map-get($icons-map, "trash");
}
.icon-three-dots:before {
    content: map-get($icons-map, "three-dots");
}
.icon-terminate:before {
    content: map-get($icons-map, "terminate");
}
.icon-suspend:before {
    content: map-get($icons-map, "suspend");
}
.icon-shareholder:before {
    content: map-get($icons-map, "shareholder");
}
.icon-salad:before {
    content: map-get($icons-map, "salad");
}
.icon-right-arrow:before {
    content: map-get($icons-map, "right-arrow");
}
.icon-phone:before {
    content: map-get($icons-map, "phone");
}
.icon-pet-other:before {
    content: map-get($icons-map, "pet-other");
}
.icon-person:before {
    content: map-get($icons-map, "person");
}
.icon-message:before {
    content: map-get($icons-map, "message");
}
.icon-male:before {
    content: map-get($icons-map, "male");
}
.icon-mail:before {
    content: map-get($icons-map, "mail");
}
.icon-logo:before {
    content: map-get($icons-map, "logo");
}
.icon-lock:before {
    content: map-get($icons-map, "lock");
}
.icon-list:before {
    content: map-get($icons-map, "list");
}
.icon-left-arrow:before {
    content: map-get($icons-map, "left-arrow");
}
.icon-invitation:before {
    content: map-get($icons-map, "invitation");
}
.icon-heart:before {
    content: map-get($icons-map, "heart");
}
.icon-hamburger:before {
    content: map-get($icons-map, "hamburger");
}
.icon-grid:before {
    content: map-get($icons-map, "grid");
}
.icon-file:before {
    content: map-get($icons-map, "file");
}
.icon-female:before {
    content: map-get($icons-map, "female");
}
.icon-eye:before {
    content: map-get($icons-map, "eye");
}
.icon-export:before {
    content: map-get($icons-map, "export");
}
.icon-error:before {
    content: map-get($icons-map, "error");
}
.icon-edit:before {
    content: map-get($icons-map, "edit");
}
.icon-download:before {
    content: map-get($icons-map, "download");
}
.icon-double-arrow-right:before {
    content: map-get($icons-map, "double-arrow-right");
}
.icon-double-arrow-left:before {
    content: map-get($icons-map, "double-arrow-left");
}
.icon-dog:before {
    content: map-get($icons-map, "dog");
}
.icon-doc-chart:before {
    content: map-get($icons-map, "doc-chart");
}
.icon-dislike:before {
    content: map-get($icons-map, "dislike");
}
.icon-company:before {
    content: map-get($icons-map, "company");
}
.icon-company-new:before {
    content: map-get($icons-map, "company-new");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-check:before {
    content: map-get($icons-map, "check");
}
.icon-change:before {
    content: map-get($icons-map, "change");
}
.icon-cat:before {
    content: map-get($icons-map, "cat");
}
.icon-carret-down:before {
    content: map-get($icons-map, "carret-down");
}
.icon-cake:before {
    content: map-get($icons-map, "cake");
}
.icon-building:before {
    content: map-get($icons-map, "building");
}
.icon-block:before {
    content: map-get($icons-map, "block");
}
.icon-back:before {
    content: map-get($icons-map, "back");
}
.icon-archive:before {
    content: map-get($icons-map, "archive");
}
.icon-alert:before {
    content: map-get($icons-map, "alert");
}
