.tableActions {
    position: relative;
    font-size: 14px;
    color: #3f3f46;

    .trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 8px 0;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 20px;
            color: #be123c;
        }

        &:hover {
            cursor: pointer;
            background-color: #fff1f2;
        }
    }
    .dropdown {
        position: absolute;
        display: none;

        width: 200px;
        right: 0;
        padding-top: 4px;
        opacity: 0;
        z-index: 2;
        animation: fadeIn 150ms ease-in forwards;
    }
    .dropdownContent {
        display: flex;
        flex-direction: column;
        padding: 5px;
        width: 100%;
        background-color: #fff;
        border: 1px solid var(--Zinc-300, #d4d4d8);
        box-shadow: 0px 4px 6px 0px rgba(24, 24, 27, 0.09);
    }
    .dropdownItem {
        padding: 6px 8px 6px 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 300ms ease-in-out;
        line-height: 20px;
        font-weight: 500;
        &:hover {
            background-color: #fff1f2;
            cursor: pointer;
        }

        i {
            font-size: 16px;
            height: 16px;
        }
    }
    &.active {
        .dropdown {
            display: block;
        }
        .trigger {
            background-color: #fff1f2;
        }
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
