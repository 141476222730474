.languageSwitcher {
    padding: 10px 24px 0 20px;
    font-size: 12px;
    gap: 8px;
    display: flex;
    align-items: center;

    .spacer {
        width: 1px;
        background-color: #3f3f46;
        height: 20px;
    }

    .languageButton {
        color: #3f3f46;
        transition: all 300ms ease-in-out;
        padding: 8px;

        &:hover {
            color: #be123c;
            cursor: pointer;
        }
        &.active {
            color: #be123c;
            font-weight: bold;
        }
    }
}
